.menu{
    border-bottom : unset !important;
}
.menu_esquerda{
    flex-wrap: wrap;
    background-color: unset;
    padding-bottom: 1rem;
}

.rodapeSalvar{
    position: absolute;
    z-index: 8;
    height: 60px;
    display: grid;
    align-items: center;
    background-color: #fff;
    color: #fff;
    bottom: 0;
    width: 97%;    
    border-radius: 0px 0px 4px 4px;    
}

.btn-salvar-chamado{
    margin-right: 0px;
    width: 90%;    
    justify-self: center;
}

#esquerda .nav-tabs{
    justify-content: center;
}
#esquerda .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: unset;
    border-radius: 0px;
}
#esquerda .nav-link{
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

#esquerda a:hover{
    color: var(--dommus-color);
}
#esquerda .nav-link.active{
    color: var(--dommus-color) !important;
    border-color:  var(--dommus-color);
    border-bottom: solid;
    border-radius: unset;
}
#esquerda .menu_esquerda a{
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
}
#esquerda .input-group-append{
    align-self: center;
    margin-left: 5px;
}

#esquerda .header{
    display: initial;
    position: relative;
    height: var(--header-height);
    text-align: center;
    margin-bottom: 1rem;
    justify-content: center;
}

#esquerda fieldset{
    position: relative;
    padding: 0px 15px;
    margin-bottom: 20px;
}

.fieldset-esquerda legend{
    color: #666;    
    padding: 0px 10px;
}

.btn-campos{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;    
    color: white;
    width: 1.9rem;
    height: 1.9rem;
    top: -25px;
    right: 5px;
    border: none;
    font-size: 0.8rem;
}

.habilitar-edicao{
    background: var(--dommus-color);
    z-index: 100;
}

.cancelar-edicao{
    background: var(--dommus-color-red);
    z-index: 100;
    border: none;
}

.informacoes{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

#left .col-lg-2,
#left .col-lg-4,
#left .col-lg-6,
#left .col-lg-12,
#left .col-lg-10,
#left .col {
    padding: 0px 5px;
}

.informacoes .icone-midia{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;    
    padding: 0px;
    border-radius: 10px;
}

.informacoes .icone-midia svg{
    font-size: 1.5rem;
}

.informacoes hr{
    margin-top: 0;
    margin-bottom: 0;
}

.informacoes .item-info{
    display: flex;
    flex-direction: column;
    row-gap: 0px;    
    background: #f8f8f8;
    border-radius: 5px;
    padding: 5px 10px;
}

.informacoes .item-info label{
    color: #888;
    font-size: 11px;
    margin-bottom: 0px;    
}
#esquerda .informacoes .item-info span{
    font-size: 14px;  
}

.informacoes .files-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.processo-link{
    cursor: pointer;
}

.processo-link span:last-child:after{
    content: "";
}

.processo-link:hover{
    color: var(--dommus-color-2);
}

.row-gap{
    row-gap: 15px;
}


