section.equipes {
    position: fixed;
    right: 0;
    top: 75px;
    min-width: 50%;
    height: calc(100% - 75px);
    background-color: #fff;
    transform: translateX(100%);
    z-index: 12;
    transition: var(--smooth);
    padding: 10px;
    overflow: auto;
}
/* .rbc-time-header.rbc-overflowing{
    margin-right: 0px !important;
} */

section.equipes.open {
    transform: translateX(0);
    border-left: 1px solid #f2f3f8;
}

section.equipes header {
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    font-weight: 700;
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

section.equipes header .close-pane {
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 55px;
    justify-content: center;
}

.sidenav-overlay.open {
    display: block !important;
    opacity: 1;
}
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 120vh;
    background-color: rgba(0,0,0,.5);
    z-index: 7;
    display: none !important;
    transition: var(--smooth);
}

.rbc-event.event-finished {
    background: #359835;
}

#step-selector {
    max-width: 15vh;
    margin-bottom: 10px;
    margin-left: 15px;
}


.dommus-interval-step {
    max-width: 175px;
    display: flex;
    flex-direction: column;
}

.dommus-interval-step label {
    margin-bottom: 0px;
    font-size: 13px;
    color: #6a6a6a;
}

.dommus-interval-step-group {
    display: flex;
}

.interval-step-item {
    cursor: pointer;
    height: 25px;
    border: 1px solid #ccc;
    text-align: center;
    width: 3rem;
}

.interval-step-item:hover {
    opacity: 0.9;
}

.interval-step-item.active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    background-color: #e6e6e6;
    border-color: #adadad;
}

.interval-step-item:first-child {
    border-radius: 4px;
    border-right: none;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.interval-step-item:last-child {
    border-radius: 4px;
    border-left: none;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.dommus-custom-options {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
}

.agenda-do-usuario {
    color: #fff;
    background-color: #28a745;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.agenda-do-usuario:hover {
    opacity: 0.8;
}

.dommus-type-options {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.dommus-type-options label {
    margin-bottom: 0px;
    font-size: 13px;
    color: #6a6a6a;
}

.dommus-type-options-items {
    display: flex;
    flex-direction: row;
}

.dommus-type-options-item {
    cursor: pointer;
    height: 25px;
    border: 1px solid #ccc;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dommus-type-options-item:hover {
    opacity: 0.9;
}

.dommus-type-options-item.active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    background-color: #e6e6e6;
    border-color: #adadad;
}

.dommus-type-options-item:first-child {
    border-radius: 4px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.dommus-type-options-item:last-child {
    border-radius: 4px;
    border-left: none;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.dommus-options {
    display: flex;
    flex-direction: row;
}


.rbc-event-label {
    display: none !important;
}

.rbc-events-container {
    font-size: 11px;
    line-height: 1.25;
    flex-wrap: nowrap;
}

.rbc-events-container div {
    height: 40px !important;
}

.rbc-events-container div:hover {
    height: auto !important;
    background-color: green;
    z-index: 999;
    width: auto !important;
}

.rbc-event {
    border: 2px solid #fff !important;
}

.rbc-agenda-table tr{
    color: #000 !important;
    background-color: #fff !important;
}

.rbc-toolbar{
    z-index: 0!important;
}
