.coluna_direita{
    padding: 4px;
}

.coluna_direita .accordion_iten{
    cursor: pointer;
}
#direita {
    height: 100%;
    overflow-x: auto;
}

#direita .header {
    display: contents;
    position: relative;
    height: var(--header-height);
    text-align: center;
    padding-bottom: 2rem;
    justify-content: center;
}
/* DIREITA */

.boxTarefas{
    
}
.separador{
    border-bottom: 1px solid #ddd;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

#right .icon {
    right: 1.4rem;
}

#right .sidebar {
    background: var(--bg-right);
    margin: auto;
}

#direita .nav-tabs{
    justify-content: center;
}
#direita .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: unset;
    border-radius: 0px;
}
#direita .nav-link{
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
#direita a:hover{
    color: var(--dommus-color);
}
#direita .nav-link.active{
    color: var(--dommus-color) !important;
    border-color:  var(--dommus-color);
    border-bottom: solid;
}
#direita .menu_direita a{
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
}
#direita .input-group-append{
    align-self: center;
}

#direita .fotoEnvolvido svg{
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50px;
}

#direita .fotoEnvolvido{
    font-size: 60px;
    line-height: 1;
}
#direita .dadosPessoaisCliente{
    margin-bottom: 0.5rem;
}

#direita > .content {
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
}


.processo-section{
    background-color: #f8f8f8;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 5px;
    color: #666;
}

.processo-section span:first-child{
    color: var(--dommus-color-2);
}
.processo-section span:first-child::after{
    content: "  ";
}

.processo-section span::after{
    content: " | ";
}

.processo-section span:last-child::after{
    content: "";
}

.processo-section:hover{
    color: var(--dommus-color-2);
}

#right .col-lg-2,
#right .col-lg-3,
#right .col-lg-4,
#right .col-lg-6,
#right .col-lg-8,
#right .col-lg-12,
#right .col-lg-10,
#right .col-xl-6,
#right .col-xl-3,
#right .col-xl-4,
#right .col,
#direita .col,
#direita .col-lg-6,
#direita .col-lg-12,
#direita .col-xl-3,
#direita .col-xl-4,
#direita .col-xl-6 
{
    padding: 0px 5px;
}

.dados-proponente,.dados-venda,.dados-produto{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row-gap{
    row-gap: 15px;
}

.dados-proponente hr,
.dados-venda hr,
.dados-produto hr{
    margin: 0px;
}

.dados-proponente .item-info,
.dados-venda .item-info,
.dados-produto .item-info{
    display: flex;
    flex-direction: column;
    row-gap: 0px;    
    background: #f8f8f8;
    border-radius: 5px;
    padding: 5px 10px;   
}

.dados-proponente .item-info label,
.dados-venda .item-info label,
.dados-produto .item-info label
{
    color: #888;
    font-size: 11px;
    margin-bottom: 0px;    
}
.dados-proponente .item-info span,
.dados-venda .item-info span,
.dados-produto .item-info span
{
    font-size: 14px;     
}

.item-info .item-copy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dados-venda .arquivo-item{
    display: flex;
    width: 100%;
    padding: 0px 10px;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}
.dados-venda .arquivo-item:hover{
    color: var(--dommus-color-2);
}

.tickets-semelhantes-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ticket-semelhante{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.ticket-semelhante .row-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ticket-semelhante .badge-status{
    color: white;
    padding: 1px 10px;
    border-radius: 5px;
}

.nomeCliente .card-text,
.emailCliente .card-text,
.telefoneCliente .card-text
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.copy-icon{    
    color: #777;
    margin-top: 3px;
    cursor: pointer;    
}

.questionarios-ticket-section{
    display: flex;
    flex-direction: column;
    gap: 10xp;
    padding: 10px 5px;
}

.questionarios-ticket-section .card-header{
    cursor: pointer;
}
