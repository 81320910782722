.listaMensagens button{
    margin-left: unset !important;
    cursor: pointer;
}



.inputMensagem:focus{
    box-shadow: unset;
}

.enviarMensagem{
    font-size: 16px;
}

input[type="file"]#file-upload {
    display: none;
}
.custom-file-upload {
    font-size: 16px;
    background-color: var(--dommus-color) ;
    border: 1px solid var(--dommus-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

#Input{
    margin-left: 0px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

#Input button{
    max-height: 3rem;
    min-height: 2.656rem;
}

#Input .btn-send{
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
}

#Input .btn-options button{
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
}

#Input .input-message-field {
    resize: none;
    display: block;
    overflow: hidden;
    resize: both;
    width: 100%;
    cursor: text;
    max-width: 80%;
    overflow-y: auto;
    font-size: 14px;
    min-height: 30px;
    max-height: 15rem;
    line-height: 20px;    
    padding: 10px 10px;
    border: 1px gainsboro solid;
    background: white;
    color: black;
    resize: none;
}

.editor-message-field {
    resize: none;
    display: block;
    overflow: hidden;
    resize: both;
    width: 100%;
    cursor: text;
    max-width: 100%;
    overflow-y: auto;
    font-size: 14px;
    min-height: 30px;
    max-height: 21rem;
    line-height: 20px;    
    padding: 10px 10px;
    border: 1px gainsboro solid;
    background: white;
    color: black;
    resize: none;
}

#Input .opcaoMenu{
    padding: 3px 10px;
}

#Input .opcaoMenu:hover{
    background-color: white;
}

#Input .opcaoMenu .item-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 14px;
} 

#Input .opcaoMenu .opcao-enviar-arquivo svg{
    color: var(--dommus-color-2);
}

#Input .opcaoMenu .opcao-enviar-arquivo:hover{
    color: var(--dommus-color-2);
}

.upload-area{
    padding: 10px;
}

.upload-area p{
    margin: 0;
    width: 100%;
    height: 120px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
}

.input-message-field:active, 
.input-message-field:focus {
    outline: none;
    box-shadow: none;
}

.editor-enviar-button{
    margin: 5px;
    padding: 5px;
}

.editor-enviar-button{
  width: 100%;
  margin: 0;
}

.dropdown-button-editor{
    margin: 5px;
}

.group-options-editor{
    display: flex;
    gap: 5px;
    align-items: center;
}

.btn-open-editor{
  height: fit-content;
}

.btn-open-editor:hover{
  color: white;
}

.btn-open-editor.ativo{
  background-color: var(--dommus-color-2);
  border-color: var(--dommus-color-2);
}

.group-options-editor .btn-open-editor{
  margin-right: 0 !important;
}
