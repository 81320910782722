.items-galeria{
    display: flex;
    padding: 10px 15px;
    gap: 20px;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 40vh;
}

.image-checkbox input[type="checkbox"]{
    display: none;
}

.image-section{
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 0;
}

.image-section .image-item{
    max-width: 10rem;
    max-height: 10rem;  
    border-radius: 10px;
    cursor: pointer;
    transition: all 500ms;
}

.image-section .image-item:active{
    transform: scale(0.98);
}

.item-selecionado{
    position: absolute;
    display: flex;
    padding: 10px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    justify-content: center;
    align-items: center;
    background: var(--dommus-color-green);
    color: white;
    font-size: 1rem;
    z-index: 100;
}

.image-section:hover .btn-excluir-item{
    display: flex;
}

.btn-excluir-item{
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    bottom: 5px;
    right: 5px;
    background-color: var(--dommus-color-red);
    color: white;
    padding: 15px;
    width: 1.2rem;
    height: 1.2rem;    
    border-radius: 5px;
    z-index: 100;
}

.divImgBanner {
    width: 100%;
}

.modalImgPortal {
    max-width: 100%;
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.modalImgPortalBanner {
    max-width: 100%;
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.botoesEditarFoto {
    position:absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.checkbox-imagem-galeria .form-check-input {
    margin-left: 0.5rem!important;
}

.checkbox-imagem-galeria {
    background-color: var(--dommus-color);
    margin-bottom: 0px;
}

.checkbox-anexo {
    background-color: var(--dommus-color);
}

.cardArquivo {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 15rem;
    margin: 10px;
    border: 0.25rem solid rgba(158, 155, 155, 0.2);
    display: flex;
    justify-content: end;
    padding: 0.5rem;
}

.cardArquivo input[type="checkbox"]{
    display: none;
}

.cardArquivo .item-selecionado{
    position: absolute;
    display: flex;
    padding: 10px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    justify-content: center;
    align-items: center;
    background: var(--dommus-color-green);
    color: white;
    font-size: 1rem;
    z-index: 100;
}

.cardArquivo .row{
    font-size: 1rem;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.checkbox-anexo .form-check {
    padding-left: 1.65rem;
}

.fixed-height-attachment{
    height: 20rem;
    overflow-y: auto;
}

.area_btn_enviar{
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: flex-end;
}

.area_btn_enviar .btn-enviar-anexos{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--dommus-color);
    border: none;
}

.area_btn_anexar{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    border-radius: 5px !important;
}