.conteudoFianceiro {
  display: contents;
  font-size: 10px;
  margin: 0;

  
}
.tebelaFinanceiro {
  text-align: center;
  margin: 0;
  width: -webkit-fill-available;
}
.cardTabela {
  padding: 0 !important;
}

.tebelaFinanceiro .spinner-border-sm{
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: 2px;
}