.historico .dotHistorico{
    background-color: var(--dommus-color-2) !important;
}

.historico{
    align-items: flex-end;
}

.historico .paper{
    padding: 6px 16px;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
}

.historico .paper .user-label{
    font-weight: 600;
    color: grey;
    font-size: 0.7rem;
}

.historico .item::before{
    content:unset;
}
.historico .labelHora{
    color: #868aa8;
    font-size: 12px;
}