* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100%;
}

body {
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
    font-size: 14px;
    background-color: var(--dommus-background-color) !important;
}

.badgeBase {
    color: white;
    font-weight: bold;
    padding: 5px 5px;
    border-radius: 5px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
}

.content {
    width: calc(100% - 50px);
    padding: 15px;
    margin-top: 75px;
    overflow-x: hidden;
}

/* Variaveis */

:root {
    /* Cores */
    --dommus-color: #183b64;
    --dommus-color-2: #f7941d;
    --dommus-color-secondary: #64B5F6;
    --dommus-color-cinza: #868aa8;
    --dommus-color-green: #4CAF50;
    --dommus-color-secondary-light: #3074c3;
    --dommus-color-red: #f44336;
    --dommus-color-dark: #353252;
    --dommus-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width: 6px;
    --dommus-overflow-height: 6px;
    --dommus-background-color: #f2f3f8;
    /*  */
    --smooth: 200ms ease-in-out;
}

*::-webkit-scrollbar-track {
    background-color: #ddd;
}

*::-webkit-scrollbar {
    width: var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--dommus-color);
    border-radius: 10px;
}

.btn-primary{
    background-color: var(--dommus-color) !important;
}

.btn-primary.focus, .btn-primary:focus{
    background-color: var(--dommus-color) !important;
}

.page-item.active .page-link{
    background-color: var(--dommus-color) !important;
    color: #fff !important;
}

.page-link{
    color: var(--dommus-color) !important;
}

.form-control .form-control-color {
    padding: .375rem 14rem !important;
}

.form-group .react-datepicker-wrapper {
    width: 100%;
}

.icon-em-construcao {
    cursor: not-allowed;
    color: var(--dommus-color-cinza);
    opacity: 40%;
}

.buttonNovaOportunidade.dropup {
    background: none;
}

.buttonNovaOportunidade.dropup .dropdown-toggle::after {
    content: none;
}

.buttonNovaOportunidade.dropup .btn {
    background-color: transparent;
}

.buttonNovaOportunidade .btn.btn-primary {
    background-color: var(--dommus-color);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    padding: 15px;
    font-size: 35px;
    border-radius: 100%;
    cursor: pointer;
}

.inputLabel {
    color: #868aa8 !important;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400 !important;
}

.w-90 {
    width: 90%;
}

.fundo-cinza {
    background-color: #ddd!important;
}

.dommus-button {
    background-color: var(--dommus-color) !important;
    border-color: var(--dommus-color) !important;
}

.dommus-button:hover {
    background-color: var(--dommus-color-2) !important;
    border-color: var(--dommus-color-2) !important;
}

.dropdown-menu{
    padding: 0 !important;
}

.dropdown-item{
    padding: 1rem !important;
    font-size: .9rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.108) !important;
}
.dropdown-item:last-child{
    border-bottom: none !important;
}

.dropdown-item svg{
    margin-right: 10px;
}

@media (max-width:767px) {
    .wrapper{
        justify-content: center;
        width: 100%;
    }

    .content{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}